import { Box } from '@mui/material'
import { education } from '../../constants'
import Title from '../title/title'

import './education.css'

const Education = (props) => {
  const { tablet } = props

  return (
    <>
      <Title title={'Education'} />
      <Box className="education-container">
        {education.map((edu) => {
          return (
            <Box
              className="edu-container"
              key={edu.degree}
              sx={{
                width: tablet ? '100%' : '50%',
                marginBottom: tablet && '10px',
              }}
            >
              <h4>{edu.degree}</h4>
              <p>{edu.school}</p>
              <p>
                {edu.date} - GPA: {edu.gpa}
              </p>
            </Box>
          )
        })}
      </Box>
    </>
  )
}

export default Education
