import { Box } from '@mui/material'
import { experience } from '../../constants'
import Title from '../title/title'

import './experience.css'

const Experience = () => {
  return (
    <>
      <Title title={'Relevant Professional Experience'} />
      <Box className="experience-container">
        {experience.map((item) => {
          return (
            <Box
              className="experience-item"
              key={item.title}
            >
              <h4>{item.title}</h4>
              <p>
                {item.company} - {item.location}
              </p>
              <p>{item.time}</p>
              <p>{item?.desc}</p>
              {
                item.link && (
                  <a href={item.link}>{item.link}</a>
                )
              }
              {
                item.list && (
                  <ul>
                    { item.list.map((listItem) => (
                      <li>{listItem}</li>
                    ))}
                  </ul>
                )
              }
            </Box>
          )
        })}
      </Box>
    </>
  )
}

export default Experience
