import { Box } from "@mui/material";

import './title.css';

const Title = ({ title }) => {
  return(
    <Box className='titleBox'>
      <h2>{title}</h2>
    </Box>
  )
}

export default Title;
