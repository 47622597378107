import EmailIcon from '@mui/icons-material/Email'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GitHubIcon from '@mui/icons-material/GitHub'

export const personalInfo = {
	name: 'David Brandon',
	city: 'Hutchinson, MN',
	phone: '612-242-9329',
	email: 'davidbrandon713@gmail.com',
}

export const about = `I am a detail-oriented college graduate eager to begin 
                        a career utilizing my background and education in 
                        programming and web development. Aside from coding, 
                        in my free time I enjoy playing video games, reading,
                        and going for long bike rides (when the weather permits).`

export const websites = [
	{
		name: 'Email',
		link: 'mailto:davidbrandon713@gmail.com',
		icon: EmailIcon,
	},
	{
		name: 'LinkedIn',
		link: 'https://www.linkedin.com/in/davidbrandon713/',
		icon: LinkedInIcon,
	},
	{
		name: 'GitHub',
		link: 'https://github.com/davidbrandon713',
		icon: GitHubIcon,
	},
]

export const education = [
	{
		school: 'Ridgewater College',
		degree: 'Associate of Applied Science: Computer Programming',
		gpa: '3.47',
		date: '05/2022',
	},
	{
		school: 'Ridgewater College',
		degree: 'Associate of Arts: Liberal Arts and Sciences',
		gpa: '3.25',
		date: '05/2020',
	},
]

export const skills = [
	'React',
	'React Native',
	'Javascript',
  'Typescript',
	'CSS / SCSS',
	'HTML',
	'SQL',
	'AWS',
	'NoSQL',
	'Firebase',
	'Netlify',
	'Python',
	'Java (Android)',
	'PHP',
	'C#',
	'Network Security',
]

export const certifications = [
  {
    name: 'CompTIA A+',
    completed: 'In progress',
  },
	{
		name: 'Udemy - Complete React Developer 2023',
		completed: '02/2023',
		link: 'https://www.udemy.com/course/complete-react-developer-zero-to-mastery/',
	},
	{
		name: 'Udemy - Junior to Senior Web Developer',
		completed: '01/2023',
		link: 'https://www.udemy.com/course/the-complete-junior-to-senior-web-developer-roadmap/',
	},
	{
		name: 'LinkedIn Front-End assessment',
		completed: '10/2022',
	},
	{
		name: 'LinkedIn Javascript assessment',
		completed: '09/2022',
	},
	{
		name: 'LinkedIn HTML assessment',
		completed: '08/2022',
	},
	{
		name: 'LinkedIn CSS assessment',
		completed: '08/2022',
	},
	{
		name: 'Yoast SEO For Beginners',
		completed: '04/2022',
	},
]

export const personalProjects = [
	{
		name: 'Relic Tracker',
		time: '1 month',
		desc: `Full stack CRUD application to keep track of my loot statistics in 
            a video game, previously built with C#`,
		tech: 'React, NodeJS, Express, MongoDB',
		link: 'https://github.com/davidbrandon713/relictracker',
	},
	{
		name: 'Market Emulator',
		time: '1 week',
		desc: `Market site used to display current user buy/sell orders, uses 
            an Express server as a proxy for accessing Warframe Market's API`,
		tech: 'React, NodeJS, Express',
		link: 'https://github.com/davidbrandon713/wfm-frontend',
	},
	{
		name: 'ChatGPT Terminal App',
		time: '1 day',
		desc: `Uses GPT-3.5-Turbo to facilitate conversation between the user
            and ChatGPT within the cmd terminal`,
		tech: 'OpenAI, Javascript',
		link: 'https://github.com/davidbrandon713/chatgpt',
	},
	{
		name: 'Discord Bot',
		time: '2 weeks',
		desc: `Uses Warframe Market's public API to display current orders, 
            and other fun commands such as a gambling game with a leaderboard`,
		tech: 'Python',
		link: 'https://github.com/davidbrandon713/kappabot',
	},
]

export const academicProjects = [ // academic and personal
	// {
	// 	name: 'PalletGeo',
	// 	time: '9 months',
	// 	desc: `Website and mobile app that allows people to easily buy and sell pallets
  //           without needing to go through a broker`,
	// 	tech: 'React, React Native, Typescript, NextJS',
	// 	link: 'https://www.palletgeo.com/',
	// },
	{
		name: 'Crwn Clothing',
		time: '1.5 months',
		desc: `Fully typed e-commerce site that features authentication, checkout session 
            management, and functional payments`,
		tech: 'React, Typescript, Firebase, Stripe',
		link: 'https://harmonious-phoenix-bf1c67.netlify.app/',
	},
	{
		name: 'Smart Brain',
		time: '1 month',
		desc: `Uses ClarifAI API to draw boxes around faces in images,
            features authentication and a badge given by a serverless function`,
		tech: 'ClarifAI, Docker, PostgreSQL, Redis, AWS Lambda',
		link: 'https://github.com/davidbrandon713/smart-brain',
	},
	{
		name: 'Android Apps',
		time: '3 months',
		desc: `Encrypted chat app, tic-tac-toe, rock paper scissors, and more apps
            all using Android Studio`,
		tech: 'Java, Android Studio',
	},
	{
		name: 'Databases',
		time: '9 months',
		desc: `Built SQL and NoSQL databases from the ground up for user authentication,
            session management, and general data storage`,
		tech: 'MySQL Workbench, PGAdmin, MongoDB Cloud, Firebase, Redis',
	},
	{
		name: 'Virtual Machines',
		time: '6 months',
		desc: `Configured Win Server 2019, Win 7, and Win 10 machines, 
            maintained network security, organization, and operation`,
		tech: 'VMware, VirtualBox, Hyper-V, WSL',
	},
]

export const experience = [ // relevant profressional experience
  {
    title: 'Junior Developer',
    company: 'PalletGeo',
		location: 'Minneapolis, MN',
		desc: `Website and mobile app that allows people to easily buy and sell pallets`,
    link: 'https://www.palletgeo.com/',
    list: [
      'Created server-side and client-side pages and components',
      'Implemented API logic in the front end',
      'Utilized Google Maps API to make an interactive map with dynamic listings',
      'Used CSS to enhance the user experience with a mobile-first philosophy',
      'Optimized SEO on each page with relevant metadata',
      'Worked with a team of 4 developers remotely',
    ],
    time: '12/2023 to Present'
  },
	{
		title: 'Junior Developer (Paid Internship)',
		company: 'PalletGeo',
		location: 'Minneapolis, MN',
		time: '02/2023 to 12/2023',
	},
	// {
	// 	title: 'Invoice Processor',
	// 	company: 'Brandon Tire',
	// 	location: 'Hutchinson, MN',
	// 	desc: 'Complete customer sales invoices',
	// 	time: '07/2016 to Present',
	// },
	// {
	// 	title: 'Front Line Server',
	// 	company: 'Qdoba Mexican Eats',
	// 	location: 'Hutchinson, MN',
	// 	desc: 'Responsible for all aspects of daily restaurant operation',
	// 	time: '01/2017 to 08/2022',
	// },
]
