import { Box, Avatar, SvgIcon } from "@mui/material";
import { websites } from "../../constants";
import { personalInfo } from "../../constants";

import './header.css';


const Header = (props) => {
  const { mobile } = props

  return (
    <div className='header-container'>
      <Box sx={{
        display: "flex",
        maxWidth: "1200px",
        margin: "0 auto",
        flexWrap: mobile ? "wrap" : "none",
        padding: "15px 0",
        justifyContent: mobile && "center"
      }}>
        <Avatar 
        src='david.jpg' 
        variant="circular"
        alt='David' 
        sx={{
          width: '180px', 
          height: '180px', 
          margin: '10px'
        }}/>
        <Box 
        className='header-info'
        sx={{
          width: mobile ? "100%" : "600px",
          margin: "auto",
          textAlign: "center",
        }}>
          <h1>{personalInfo.name}</h1>
          <p>{personalInfo.email}</p>
          <p>{personalInfo.city}</p>
          <p>{personalInfo.phone}</p>
        </Box>
        <Box 
        className='header-sites'
        sx={{
          width: mobile ? '360px' : '200px',
          marginTop: mobile && '4px',
        }}>
          {websites.map((site, key) => {
            return (
              <Box 
              className='header-site' 
              key={`${site.link}-${key}`}
              sx={{
                width: mobile ? '33%' : '100%',
                textAlign: mobile ? 'center' : 'left',
              }}>
                <a href={site.link} target='_blank' rel='noreferrer'>{<SvgIcon component={site.icon} />}{site.name}</a>
              </Box>
            )
          })}
        </Box>
      </Box>
    </div>
  )
}

export default Header;
