import { Box } from "@mui/material";
import { skills } from "../../constants";
import Title from "../title/title";

import './skills.css';

const Skills = (props) => {
  const { mobile } = props
  return (
    <>
    <Title title={'Skills'} />
    <Box className='skills-container'>
      {skills.map(skill => {
        return (
          <Box 
          className='skill-container' 
          key={skill}
          sx={{
            margin: 0,
            width: mobile ? '100%' : '50%',
          }}>
            <p>{skill}</p>
          </Box>
        )
      })}
    </Box>
    </>
  )
}

export default Skills;