import { Box } from "@mui/material";
import { certifications } from "../../constants";
import Title from "../title/title";

import './certifications.css';

const Certifications = (props) => {
  const { mobile } = props
  return (
    <>
    <Title title={'Certifications'} />
    <Box 
    className='certifications-container'>
      {certifications.map(cert => {
        return (
          <Box 
          className='cert-container' 
          key={cert.name}
          sx={{
            width: mobile ? '100%' : '50%',
            marginBottom: '10px',
          }}>
            {cert.link ? 
              (<a 
                href={cert.link} 
                target='_blank' 
                rel='noreferrer'
                >
                  <h4>{cert.name}</h4>
                </a>
              ) :
              <h4>{cert.name}</h4>
            }
            <p>{cert.completed}</p>
          </Box>
        )
      })}
    </Box>
    </>
  )
}

export default Certifications;