
import { Box } from "@mui/material";
import { about } from "../../constants";
import Title from "../title/title";

import './about.css';

const About = () => {
  return (
    <>
    <Title title={'About'} />
    <Box className='about-container'>
      <p>{about}</p>
    </Box>
    </>
  )
}

export default About;