import { useState, useEffect, Fragment } from 'react'
import About from '../components/about/about'
import { AcademicProjects, PersonalProjects } from '../components/projects'
import Education from '../components/education/education'
import Skills from '../components/skills/skills'
import Certifications from '../components/certifications/certifications'
import Experience from '../components/experience/experience'
import Header from '../components/header/header'

import './App.css'

function App() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const mobile = windowSize.width < 768
  const tablet = windowSize.width < 1024

  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
  }, [])

  return (
    <Fragment>
      <Header
        mobile={mobile}
        tablet={tablet}
      />
      <div className="App">
        <About
          mobile={mobile}
          tablet={tablet}
        />
        <Skills
          mobile={mobile}
          tablet={tablet}
        />
        <Experience
          mobile={mobile}
          tablet={tablet}
        />
        <PersonalProjects 
          mobile={mobile}
          tablet={tablet}
        />
        <AcademicProjects
          mobile={mobile}
          tablet={tablet}
        />
        <Certifications
          mobile={mobile}
          tablet={tablet}
        />
        <Education
          mobile={mobile}
          tablet={tablet}
        />
      </div>
    </Fragment>
  )
}

export default App
