import { Box } from '@mui/material'
import { personalProjects } from '../../constants'
import Title from '../title/title'

import './projects.css'

export const PersonalProjects = (props) => {
  const { tablet } = props
  return (
    <>
      <Title title={'Personal Projects'} />
      <Box className="projects-container personal">
        {personalProjects.map((item) => {
          return (
            <Box
              className="project-item"
              key={item.name}
              sx={{
                width: tablet ? '100%' : '50%',
                marginBottom: '10px',
              }}
            >
              {item.link ? (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4>{item.name}</h4>
                </a>
              ) : (
                <h4>{item.name}</h4>
              )}
              <p>{item.desc}</p>
              <p>
                Tech used: <i>{item.tech}</i>
              </p>
            </Box>
          )
        })}
      </Box>
    </>
  )
}